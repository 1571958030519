class AxiosBuilder {
  axios = {}

  constructor ($axios) {
    this.axios = $axios
    // this.axios.defaults.headers = {
    //   'Cache-Control': 'no-cache',
    //   Pragma: 'no-cache',
    //   Expires: '0'
    // }
  }

  config = {
    baseURL: process.env.API_BASE_URL,
    headers: {},
    params: {},
    // data: {},
    withCredentials: true
  }

  requestInterceptors = []
  responseInterceptors = []

  params (params, encodeFlag) {
    const newParams = {}
    for (const k in params) {
      if (typeof (params[k]) === 'string') {
        if (!encodeFlag) {
          newParams[k] = params[k]
        } else {
          newParams[k] = encodeURIComponent(params[k])
        }
      } else {
        newParams[k] = params[k]
      }
    }
    params = newParams
    this.config = this.resetConfig()
    this.config.params = params
    return this
  }

  data (data) {
    this.config = this.resetConfig()
    this.config.data = data
    return this
  }

  baseURL (baseURL) {
    this.config.baseURL = baseURL
    return this
  }

  contentType (contentType) {
    this.config.headers.contentType = contentType
    return this
  }

  // arraybuffer, blob, document, json, text, stream
  responseType (responseType) {
    this.config.responseType = responseType
    return this
  }

  withCredentials (withCredentials) {
    this.config.withCredentials = withCredentials
    return this
  }

  setRequestInterceptors (beforeInterceptor, errorInterceptor) {
    this.requestInterceptors.push({
      before: (config) => {
        beforeInterceptor()
        return config
      },
      error: (error) => {
        errorInterceptor()
        return Promise.reject(error)
      }
    })
    return this
  }

  setResponseInterceptors (beforeInterceptor, errorInterceptor) {
    this.responseInterceptors.push({
      before: (config) => {
        beforeInterceptor()
        return config
      },
      error: (error) => {
        errorInterceptor()
        return Promise.reject(error)
      }
    })
    return this
  }

  build () {
    const instance = this.axios.create(this.config)
    const buildConfig = Object.assign({}, this.config)

    if (buildConfig.data !== null && buildConfig.data !== undefined && buildConfig.data !== {}) {
      instance.interceptors.request.use((config) => {
        config.data = buildConfig.data

        return config
      })
    }

    instance.interceptors.response.use((config) => {
      return config
    })

    instance.interceptors.request.use((config) => {
      // const show = window.$nuxt.$store.getters['loading/isShowLoadingBar']
      // const turnOffShow = window.$nuxt.$store.getters['loading/isTurnOffShowLoading']
      // async 시작 vuex 상태

      // async 끝 vuex 상태

      // if (!끝 vuex 상태){
      //   window.$nuxt.$store.commit('loading/setLoadingBar', true)
      // }

      // if (!turnOffShow) {
      //   if (!show) {
      //     window.$nuxt.$store.commit('loading/setLoadingBar', true)
      //   }
      // } else {
      //   window.$nuxt.$store.commit('loading/setLoadingBar', false)
      // }
      window.$nuxt.$store.commit('loading/incrementRequest')
      return config
    }, (error) => {
      // window.$nuxt.$store.commit('loading/setLoadingBar', false)
      window.$nuxt.$store.commit('loading/decrementRequest')

      return Promise.reject(error)
    })

    instance.interceptors.response.use((response) => {
      // window.$nuxt.$store.commit('loading/setLoadingBar', false)
      window.$nuxt.$store.commit('loading/decrementRequest')

      return response
    }, (error) => {
      // window.$nuxt.$store.commit('loading/setLoadingBar', false)
      window.$nuxt.$store.commit('loading/decrementRequest')
      return Promise.reject(error)
    })

    this.requestInterceptors.forEach((interceptor) => {
      instance.interceptors.request.use(interceptor.before, interceptor.error)
    })

    this.responseInterceptors.forEach((interceptor) => {
      instance.interceptors.response.use(interceptor.before, interceptor.error)
    })

    return instance
  }

  resetConfig () {
    this.requestInterceptors = []
    this.responseInterceptors = []
    // this.setResponseInterceptors(this.defaultResposeBeforeInteceptor, this.defaultResposeErrorInteceptor)
    return {
      baseURL: process.env.API_BASE_URL,
      headers: {},
      params: {},
      withCredentials: true
    }
  }
}
export default ({ $axios }, inject) => {
  inject('AxiosBuilder', new AxiosBuilder($axios))
}
