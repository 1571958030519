import * as https from 'https'

export default function ({ store, $axios }, inject) {
  const instance = $axios.create({
    // baseURL: process.env.API_BASE_URL,
    headers: {
    },
    // 'Cache-Control': 'no-cache',
    // Pragma: 'no-cache',
    // Expires: '0'
    params: {},
    withCredentials: true,
    httpsAgent: new https.Agent({
      rejectUnauthorized: false
    })
  })
  instance.setBaseURL(process.env.API_BASE_URL)
  // 요청이 발생하면 함수 호출
  instance.onRequest((config) => {
    // console.log('instanceAxios', config.params)
    const newParams = {}
    for (const k in config.params) {
      if (typeof (config.params[k]) === 'string') {
        newParams[k] = encodeURIComponent(config.params[k])
      } else {
        newParams[k] = config.params[k]
      }
    }
    config.params = newParams
  })

  // 요청 실패가 발생하면 함수 호출
  instance.onRequestError((error) => {
    alert(error)
    // const code = parseInt(error.response && error.response.status)
    // if (code === 400) {
    //   redirect('/400') // 400 에러 발생시 /400으로 리다이렉트
    // }
    return Promise.reject(error)
  })
  // 요청/응답 상관없이 에러가 발생하면 함수 호출
  instance.onError((error) => {
    return Promise.reject(error)
  })
  // 응답을 수신하면 함수 호출
  instance.onResponse((response) => {
    // if (!response.data.success) {
    //   return new Error('fail response')
    // } else {
    //   return response
    // }
  })
  // 응답 실패가 발생하면 함수 호출
  instance.onResponseError((error) => {
    return Promise.reject(error)
  })

  // 인스턴스 주입
  inject('api', instance)

  // vuex store 인스턴스 주입
  store.$api = instance
}

// 토큰의 인증 스킴 지정 가능, 'Authorization : Bearer 123'이 등록됨
// ex) $axios.setToken("123", "Bearer");

// 토큰을 첨부할 요청 메서드 선택 가능, post, put, delete 요청을 보낼 경우만 헤더에 'Authorization : Bearer 123'이 등록됨
// ex) $axios.setToken("123", "Bearer", ["post", "put", "delete"]);
