import Vue from 'vue'
import 'babel-polyfill'
import * as rules from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
// import { messages } from 'vee-validate/dist/locale/ko.json'

// Object.keys(rules).forEach((rule) => {
//   extend(rule, rules[rule])
// })
// Object.keys(rules).forEach((rule) => {
//   extend(rule, {
//     ...rules[rule], // copies rule configuration
//     message: messages[rule] // assign message
//   })
// })
extend('required', {
  ...rules.required,
  message: '{_field_} 필드는 필수 값 입니다.'
})
extend('email', {
  ...rules.email,
  message: '{_field_} 필드가 유효하지 않습니다.'
})
extend('numeric', {
  ...rules.numeric,
  message: '{_field_} 필드는 숫자만 입력 가능합니다.'
})
extend('min', {
  validate (value, { length }) {
    return String(value).length >= length
  },
  params: ['length'],
  message: '{_field_} 필드의 문자 개수는 {length}자 이상만 허용됩니다..'
})
extend('max', {
  validate (value, { length }) {
    return String(value).length <= length
  },
  params: ['length'],
  message: '{_field_} 필드의 문자 개수는 {length}자 이하만 허용됩니다.'
})
extend('min_value', {
  validate (value, { min }) {
    return Number(value) >= min
  },
  params: ['min'],
  message: '{_field_} 필드는 {min} 이상만 허용됩니다.'
})
extend('max_value', {
  validate (value, { max }) {
    return Number(value) <= max
  },
  params: ['max'],
  message: '{_field_} 필드는 {max} 이하만 허용됩니다.'
})
extend('oneOf', {
  validate (value, { candidates }) {
    console.log('value(' + typeof value + '): ', value)
    console.log('candidates(' + typeof candidates + '): ', candidates)
    console.log('indexOf? ' + candidates.indexOf(value))
    return candidates.includes(String(value))
  },
  params: ['candidates'],
  message: '{_field_} 필드는 {candidates} 중 하나이어야 합니다.'
})
extend('password_length', {
  validate (value, { length }) {
    return !value || value.length >= length
  },
  params: ['length'],
  message: '{length}자 이상 입력해야 합니다.'
})
extend('password_confirm', {
  validate (value, { target }) {
    return value === target
  },
  params: ['target'],
  message: '입력된 비밀번호가 다릅니다.'
})
extend('alphadash_numeric', {
  validate: (value) => {
    const regex = /^[aA-zZ|0-9|_]*$/.test(value)
    return regex
  },
  message: '{_field_} 필드는 영문, 숫자, _ 만 입력 가능합니다.'
})
extend('alphadash_numeric_slash', {
  validate: (value) => {
    const regex = /^[aA-zZ|0-9|/|_]*$/.test(value)
    return regex
  },
  message: '{_field_} 필드는 영문, 숫자, _ , / 만 입력 가능합니다.'
})
extend('alphakordash_numeric', {
  validate: (value) => {
    const regex = /^[가-힣|aA-zZ|0-9|_]*$/.test(value)
    return regex
  },
  message: '{_field_} 필드는 한글, 영문, 숫자, _ 만 입력 가능합니다.'
})
extend('alphakordash_numeric_slash', {
  validate: (value) => {
    const regex = /^[가-힣|aA-zZ|0-9|/|_]*$/.test(value)
    return regex
  },
  message: '{_field_} 필드는 한글, 영문, 숫자, _ , / 만 입력 가능합니다.'
})

extend('phone', {
  validate: (value) => {
    const regex = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/.test(value)
    return regex
  },
  message: '{_field_} 필드가 유효하지 않습니다'
})

export const extender = namespace => (rule, schema) => {
  extend(namespace + '/' + rule, schema)
}

// require('@/validations/member').default(extender('mb'))

export const install = (context, inject) => {
  Vue.component('ValidationProvider', ValidationProvider)
  Vue.component('ValidationObserver', ValidationObserver)
}

export default install
