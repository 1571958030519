import { internationalTimeFormatter } from '@/plugins/common/internationalization/international-time-formatter'
export function time (value) {
  if (!value) {
    return ''
  }
  const format = arguments[1]
  return internationalTimeFormatter.formatTime(value, format)
}
export function date (value) {
  if (!value) {
    return ''
  }
  const format = arguments[1]
  return internationalTimeFormatter.formatDate(value, format)
}
export function datetime (value) {
  if (!value) {
    return ''
  }
  const format = arguments[1]
  return internationalTimeFormatter.formatDatetime(value, format)
}
export function month (value) {
  if (!value) {
    return ''
  }
  const format = arguments[1]
  return internationalTimeFormatter.formatMonth(value, format)
}
