import { createNamespacedStoreExposer } from '~/plugins/common/vuex/vuex-helper'

const init = () => ({
  guest: {
    id: undefined,
    name: undefined,
    phone: undefined
  }
})

const state = init

const getters = {
  getGuestInfo: state => state.guest,
  getGuestId: state => state.guest.id,
  getGuestName: state => state.guest.name,
  getGuestPhone: state => state.guest.phone
}

const mutations = {
  init: (state) => {
    state.guest.id = undefined
    state.guest.name = undefined
    state.guest.phone = undefined
  },
  /*
    $store.commit('guest/setGuest', {
      id: 'testId',
      name: 'testName',
      phone: '010-1111-2222'
    })
  */
  setGuest: (state, guest) => {
    state.guest.id = guest.id
    state.guest.name = guest.name
    state.guest.phone = guest.phone
  }
}

const actions = {
  init (commit) {
    commit('INIT')
  }
}

// 뷰 인스턴스에 노출하기 위한 노출 함수 제공.
export const { storeGetters, storeActions } = createNamespacedStoreExposer('guest')(getters, actions)

// 스토어 내보내기.
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
