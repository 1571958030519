import { createNamespacedHelpers } from 'vuex'

/**
 * # map 객체를 이용해서 source 를 노출합니다. 가변인자 ...args 가 존재하지 않는 다면 source 의 모든 키를 노출합니다.
 * @param map
 * @param source
 * @param args
 * @returns {*}
 */
export const spread = (map, source, ...args) => {
  let expose
  if (!args || args.length === 0) {
    expose = Object.keys(source)
  } else {
    expose = args
  }
  return map(expose)
}

/**
 * vuex store 의 namespace 기능을 이용해서 getters 와 actions 을 노출할 수 있는 헬퍼함수를 제공합니다.
 * @param namespace
 * @returns {function(*=, *=): {}}
 */
export const createNamespacedStoreExposer = (namespace) => {
  const { mapGetters, mapActions } = createNamespacedHelpers(namespace)
  return (getters, actions) => {
    const spreaders = {}
    if (getters) {
      spreaders.storeGetters = (...args) => spread(mapGetters, getters, ...args)
    }
    if (actions) {
      spreaders.storeActions = (...args) => spread(mapActions, actions, ...args)
    }
    return spreaders
  }
}
