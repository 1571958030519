import parseTime from '@/plugins/common/time/time-parser'

export default class TimeFormatter {
  formatTime (v, format) {
    throw new Error('formatDate function must be implemented.')
  }

  formatDate (v, format) {
    throw new Error('formatDate function must be implemented.')
  }

  formatDatetime (v, format) {
    throw new Error('formatDate function must be implemented.')
  }

  format (v, fmt) {
    const datetime = parseTime(v)
    return datetime.toFormat(fmt)
  }
}
