
import { set } from 'vue'
import { createNamespacedStoreExposer } from '~/plugins/common/vuex/vuex-helper'

const state = () => ({

  /** 콘텐츠커머스 필터, 콘텐츠 유형 정보 저장 store
   * 콘텐츠유형, 정렬필터, 콘텐츠상세아이디
   *
   *    * 마이페이지>오늘의발견관리
   * 콘텐츠 유형, 콘텐츠 상태, 탭, 콘텐츠 상세아이디
  */
  ctenTyCdList: undefined,
  ctenSortTy: '',
  ctenDtlId: undefined,
  thmeId: undefined,
  tab: undefined,
  ctenSttus: undefined

})

const mutations = ({
  /**
   * 구좌별 상품 리스트 저장 업데이트
   * @param {*} state 저장소 정보(state.material)
   * @param {Array} productList 업데이트할 신규 데이터
   * @param {String} 저장소 키  => 구좌ID : exhCmptmtId
   *                              products_(구좌ID : exhCmptmtId)_(소재ID : exhMtrlNo)
   */
  setCtenTyCdList (state, ctenTyCdList) {
    state.ctenTyCdList = ctenTyCdList
  },
  setCtenSortTy (state, ctenSortTy) {
    state.ctenSortTy = ctenSortTy
  },
  setCtenDtlId (state, ctenDtlId) {
    state.ctenDtlId = ctenDtlId
  },
  setThmeId (state, thmeId) {
    state.thmeId = thmeId
  },
  setTab (state, tab) {
    state.tab = tab
  },
  setCtenSttus (state, ctenSttus) {
    state.ctenSttus = ctenSttus
  },
  resetCtenTyCdList (state) {
    delete state.ctenTyCdList
  },
  resetCtenSortTy (state) {
    delete state.ctenSortTy
  },
  resetCtenDtlId (state) {
    delete state.ctenDtlId
  },
  /**
   * 전시 구좌별 상품 정보 초기화
   * @param {*} state
   * @param {String} 저장소 키  => 구좌ID : exhCmptmtId
   */
  initDsMaterial (state, exhCmptmtId) {
    set(state.material, exhCmptmtId, {})
  },
  resetState (state, exhCmptmtId) {
    delete state.material[exhCmptmtId]
  },
  resetDtlBack (state) {
    state.ctenDtlId = undefined
    state.ctenSortTy = ''
    state.ctenTyCdList = undefined
    state.thmeId = undefined
    state.ctenSttus = undefined
    state.tab = undefined
  },

  // 페이지별 동적 id 업데이트
  setDynamicId (state, obj) {
    state.dynamicId = obj
  },
  // 대카테고리 id 업데이트
  setLgCtgrNo (state, obj) {
    for (const [key, value] of Object.entries(obj)) {
      state[key] = value
    }
  },
  // 카테고리 페이지명 업데이트
  setCtgrPgNm (state, name) {
    state.storeCtNm = name
  },
  // 0027 대카테고리 id 업데이트
  setLgCtgrId (state, obj) {
    state.lgCtgrId = obj.id
  },
  /**
   * 필터링된 묶음 상품 번호 목록 저장
   */
  setFiltetedBundleProducts (state, filteredBundleProducts) {
    state.filteredBundleProducts = filteredBundleProducts
  },

  // 탑버튼 노출여부를 위한 인풋포커스여부
  setInputFocus (state, isInputFocus) {
    state.isInputFocus = isInputFocus
  },

})

const actions = ({
  /**
   * state 저장상태 초기값 세팅
   * @param {*} key 저장소 키  => 구좌ID : exhCmptmtId
   */
  //   initProductKey ({ commit }, exhCmptmtId) {
  //     commit('initDsMaterial', exhCmptmtId)
  //   },
  //   async fetchCtgrData ({ commit, state }) {
  //     try {
  //       const lgCtgrNum = state.lgCtgrNo
  //       const mdCtgrNum = state.mdCtgrNo
  //       const smCtgrNum = state.smCtgrNo
  //       let lgCtgrList = []
  //       let mdCtgrList = []
  //       let smCtgrList = []
  //       if (lgCtgrNum !== '') {
  //         lgCtgrList = await this.$api.post('/ds/ctgr', { exhCtgrNos: ['CTGR_00001'] }).then((res) => { return res.data.data })
  //       }
  //       if (mdCtgrNum !== '') {
  //         mdCtgrList = await this.$api.post('/ds/ctgr', { exhCtgrNos: [lgCtgrNum] }).then((res) => { return res.data.data })
  //       }
  //       if (smCtgrNum !== '') {
  //         smCtgrList = await this.$api.post('/ds/ctgr', { exhCtgrNos: [mdCtgrNum] }).then((res) => { return res.data.data })
  //       }
  //       const largeCategory = lgCtgrList.length > 0 && lgCtgrList.find(item => item.exhCtgrNo === lgCtgrNum)
  //       const middleCategory = mdCtgrList.length > 0 && mdCtgrList.find(item => item.exhCtgrNo === mdCtgrNum)
  //       const smallCategory = smCtgrList.length > 0 && smCtgrList.find(item => item.exhCtgrNo === smCtgrNum)

//       // 활성화된 카테고리 페이지명
//       if (largeCategory) {
//         commit('setCtgrPgNm', largeCategory.exhCtgrNm)
//       } else {
//         commit('setCtgrPgNm', '전체')
//       }
//       return {
//         ...(largeCategory && { lgCtgr: state.lgCtgrNo, lgCtgrNm: largeCategory.exhCtgrNm }),
//         ...(middleCategory && { mdCtgr: state.mdCtgrNo, mdCtgrNm: middleCategory.exhCtgrNm }),
//         ...(smallCategory && { smCtgr: state.smCtgrNo, smCtgrNm: smallCategory.exhCtgrNm })
//       }
//     } catch (error) {
//       console.error('API 호출 중 오류 발생:', error)
//     }
//   }
})

const getters = {
  /**
   * state 업데이트된 구좌별 데이터 조회
   * @param {*} state
   * @param {String} key 저장소 키  => 구좌ID : exhCmptmtId
   * @returns 구좌, 소재별 상품 정보 Array
   */
  getCtenTyCdList: (state) => {
    return state.ctenTyCdList
  },
  getCtenSortTy: (state) => {
    return state.ctenSortTy
  },
  getCtenDtlId: (state) => {
    return state.ctenDtlId
  },
  getThmeId: (state) => {
    return state.thmeId
  },
  getTab: (state) => {
    return state.tab
  },
  getCtenSttus: (state) => {
    return state.ctenSttus
  },
  /**
   * state 저장소 키 조회
   * @param {*} state
   * @param {String} exhCmptmtId 구좌별 Id
   * @returns 저장소 키  => 구좌ID : exhCmptmtId
   */
  getStoredKey: state => (exhCmptmtId) => {
    const existingKey = Object.keys(state.material).find((name) => {
      return name.includes(exhCmptmtId)
    })
    return existingKey
  },

}

export const { storeGetters, storeActions } = createNamespacedStoreExposer('ug')(getters, actions)

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
