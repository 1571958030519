export default ({ app }) => {
  // Kakao SDK 스크립트 동적 로딩
  const kakaoScript = document.createElement('script')
  kakaoScript.src = 'https://developers.kakao.com/sdk/js/kakao.js'
  document.head.appendChild(kakaoScript)

  kakaoScript.onload = () => {
    // Kakao 초기화
    window.Kakao.init('c76ff8c45babd7968ccb5e957bb260e6')
  }
}
