import { createNamespacedStoreExposer } from '~/plugins/common/vuex/vuex-helper'

const init = () => ({
  cmnCd: {},
  other: {}
})

const state = init

const getters = {
  cmnCd: state => state.cmnCd,
  other: state => state.other
}

const mutations = {
  setCmnCd: (state, data) => {
    return (state.cmnCd[data.masterCd] = data.cmnCd)
  },
  setOther: (state, other) => (state.other = other)
}

const actions = {
  setCmnCd ({ commit }, data) {
    commit('setCmnCd', data)
  },
  setOther ({ commit }, other) {
    commit('setOther', other)
  }
}

// 뷰 인스턴스에 노출하기 위한 노출 함수 제공.
export const { storeGetters, storeActions } = createNamespacedStoreExposer('session')(getters, actions)

// 스토어 내보내기.
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
