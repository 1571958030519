let component

window.setThisComponent = function (param) {
  component = param
}

window.setGroobeeRecommend = function (algorithmCd, campaignKey, goodsArray) {
  console.log('setGroobeeRecommend', goodsArray)
  try {
    if (typeof component.getGroobeeRecommend === 'function') {
      component.getGroobeeRecommend(algorithmCd, campaignKey, goodsArray)
    } else {
      console.log('getGroobeeRecommend is not a function')
    }
  } catch (e) {
    console.log('window.setGroobeeRecommend', e)
  }
}
