import Vue from 'vue'
import VueClipBoard from 'vue-clipboard2'
import Pagination from '~/components/common/Pagination'

Vue.component('Pagination', Pagination)

VueClipBoard.config.autoSetContainer = true
Vue.use(VueClipBoard)

// EventBus 생성
Vue.prototype.$EventBus = new Vue()
