
import { createNamespacedStoreExposer } from '~/plugins/common/vuex/vuex-helper'

const state = () => ({
  giftOrderStep1: {},
  giftOrderStep2: {}
})

const getters = {
  getGiftStep1: state => state.giftOrderStep1,
  getGiftStep2: state => state.giftOrderStep2,
}

const mutations = ({
  setGiftStep1: (state, step1) => { state.giftOrderStep1 = step1 },
  setGiftStep2: (state, step2) => { state.giftOrderStep2 = step2 },
  removeGiftStep: (state) => {
    state.giftOrderStep1 = {}
    state.giftOrderStep2 = {}
  }
})

const actions = ({
  setGiftStep1 ({ commit }, step1) {
    commit('setGiftStep1', step1)
  },
  setGiftStep2 ({ commit }, step2) {
    commit('setGiftStep2', step2)
  },
  removeGiftStep ({ commit }) {
    commit('removeGiftStep')
  }
})

export const { storeGetters, storeActions } = createNamespacedStoreExposer('or')(getters, actions)

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
