import { createNamespacedStoreExposer } from '~/plugins/common/vuex/vuex-helper'

const init = () => ({
  showLoadingBar: false,
  turnOffShowLoading: false,
  activeRequests: 0
})

const state = init

const getters = {
  isLoading: state => state.activeRequests > 0
  // isShowLoadingBar: state => state.showLoadingBar,
  // isTurnOffShowLoading: state => state.turnOffShowLoading
}

const mutations = {
  incrementRequest (state) {
    state.activeRequests++
  },
  decrementRequest (state) {
    if (state.activeRequests > 0) {
      state.activeRequests--
    }
  }
}

const actions = {
  setLoadingBar ({ commit }, isShow) {
    commit('setLoadingBar', isShow)
  },
  setTurnOffShowLoading ({ commit }, isTurnOff) {
    commit('setTurnOffShowLoading', isTurnOff)
  }
}

// 뷰 인스턴스에 노출하기 위한 노출 함수 제공.
export const { storeGetters, storeActions } = createNamespacedStoreExposer('session')(getters, actions)

// 스토어 내보내기.
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
