import { createNamespacedStoreExposer } from '~/plugins/common/vuex/vuex-helper'

const state = () => ({
  id: '',
  name: ''
})

const mutations = ({
  setId: (state, id) => { state.id = id },
  setName: (state, name) => { state.name = name }
})

const actions = ({
  idAction: ({ commit }, id) => {
    setTimeout(() => {
      commit('setId', id)
      alert('setId 성공')
    }, 1000)
  },
  nameAction: ({ commit }, name) => {
    setTimeout(() => {
      commit('setName', name)
      alert('setName 성공')
    }, 1000)
  }
})

const getters = {
  getIdName: (state) => {
    let result = ''
    if (state.id === '') {
      result += 'X / '
    } else {
      result += state.id + ' / '
    }
    if (state.name === '') {
      result += 'X'
    } else {
      result += state.name
    }
    return result
  }
}

export const { storeGetters, storeActions } = createNamespacedStoreExposer('sample')(getters, actions)

export default {
  state,
  getters,
  actions,
  mutations
}
