
/** 전시 랜딩 페이지 전용 store
 * => 새로 고침시에도 전달된 정보가 유지 되어야할 경우 사용하기 위함
*/
import { set } from 'vue'
import { createNamespacedStoreExposer } from '~/plugins/common/vuex/vuex-helper'

const state = () => ({
  params: {
    rndArr: [], // 키비주얼, 판매알림상품 전체보기 파라미터
    searchApi: {}, // 검색 솔루션 쿼리정보
    recmApi: {}, // 추천 솔루션 옵션정보
    mtrlParam: {} // 전시파트 소재 옵션정보
  },
  exhCmptmtInfo: {
    exhCmptmtId: '', // 전시파트 구좌ID
    exhMtrlNo: '', // 전시파트 소재ID
    hdcpy: '', // 구좌명
    lginYn: '', // 로그인 필요여부(개인화 여부)
    exhMtrlRgMthdCd: '', // 구좌 리스트 조회(10 : 전시파트 수기등록, 20 : 검색솔루션, 30: 추천솔루션)
    insdYn: '', // api 구분(Y : 전시파트 수기등록 or 추천솔루션, N : 검색솔루션)
    url: '' // 도메인 포함 솔루션 url 정보 (ex. env.API_BASE_URL + api url)
  }
})

const mutations = ({
  /**
   * 구좌별 전체보기 추천셋 이동시 전달 파라미터 세팅
   * @param {Object} 구좌에서 전달한 파라미터
   * (검색 솔루션 : searchApi, 추천솔루션 : recmApi, 전체보기 : rndArr)
   *
   */
  setRecommendParams (state, obj) {
    if (obj.constructor === Object && Object.keys(obj).length > 0) {
      for (const [key, value] of Object.entries(obj)) {
        state.params[key] = value
      }
    }
  },
  /**
       * 전달 받은 구좌 기본정보 세팅
       * @param {*} obj 구좌의 기본정보(구좌명, 개인화 여부, 랜딩 url 정보 등)
      */
  setExhCmptmtInfo (state, obj) {
    if (obj.constructor === Object && Object.keys(obj).length > 0) {
      for (const [key, value] of Object.entries(obj)) {
        set(state.exhCmptmtInfo, key, value)
      }
    }
  },
  /**
   * state 초기화
   */
  resetState (state) {
    state.params = {}
    state.exhCmptmtInfo = {}
  }
})

const actions = ({
  /**
   * state 초기화
   * @param {*} state
   */
  initRecommendParams ({ commit }) {
    return new Promise((resolve, reject) => {
      commit('resetState')
      resolve()
    })
  },
  /**
   * @description 추천셋 업데이트
   * @param {Object} params 전달할 파라미터 정보
   *  {
   *    searchApi{Object} or recmApi{Object} 솔루션별 쿼리정보
   *    exhCmptmtInfo : 구좌 기본정보(추천셋 이동시 필수값)
   *  }
   * @returns
   *  Store 업데이트 후 추천셋으로 router 이동할것
   *  사용법 :
   *  this.$store.dispatch('ds/persisted/updateRendingSet', params).then(() => {
   *   this.$router.push({ name: 'ds-recmd-id', params: { id: 'C206' }})
   *  })
   */
  updateRendingSet ({ commit }, params) {
    commit('resetState')
    return new Promise((resolve, reject) => {
      if (params?.exhCmptmtInfo && Object.keys(params?.exhCmptmtInfo).length > 0) {
        commit('setExhCmptmtInfo', params.exhCmptmtInfo)
        delete params.exhCmptmtInfo
        commit('setRecommendParams', params)
      } else {
        commit('setRecommendParams', params)
      }
      resolve()
    })
  }
})

const getters = {
  /**
   * state 업데이트된 랜딩 데이터 조회
   * @returns 구좌 기본정보, 구좌별 전달 파라미터 정보
   */
  getRndParamInfo: (state) => {
    return ({
      ...state.params,
      exhCmptmtInfo: state.exhCmptmtInfo
    })
  }
}

export const { storeGetters, storeActions } = createNamespacedStoreExposer('ds')(getters, actions)

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
