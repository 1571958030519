import { createNamespacedStoreExposer } from '~/plugins/common/vuex/vuex-helper'

const init = () => ({
  formData: {}
})

const state = init

const getters = {
  formData: state => state.formData
}

const mutations = {
  initData (state) {
    Object.assign(state, init())
  },
  // setFormData: (state, formData) => (state.formData = formData)
  setFormData: (state, formData) => Object.assign(state.formData, formData)
}

const actions = {
  initData ({ commit }) {
    commit('initData')
  },
  setFormData ({ commit }, formData) {
    commit('setFormData', formData)
  }
}

// 뷰 인스턴스에 노출하기 위한 노출 함수 제공.
export const { storeGetters, storeActions } = createNamespacedStoreExposer('session')(getters, actions)

// 스토어 내보내기.
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
