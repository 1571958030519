import Vue from 'vue'
import UpperCaseFilter from './upper-case-filter'
import LowerCaseFilter from './lower-case-filter'
import CapitalizeFilter from './capitalize-filter'
import { time, date, datetime, month } from './time-filter'
import Mask from './mask'

/**
 * 전역 컴포넌트를 설치합니다.
 * @param Vue
 * @param options
 */
const installComponents = (Vue, options) => {
  const components = {
    /* 여기에 컴포넌트 객체를 추가합니다. 컴포넌트 객체 이름 자체가 키가 되어 전역 컴포넌트에 추가됩니다. */
  }
  Object.keys(components).forEach(key => Vue.component(key, components[key]))
  Vue.use(Mask)
}

const installFilters = (Vue, options) => {
  Vue.filter('uppercase', UpperCaseFilter)
  Vue.filter('lowercase', LowerCaseFilter)
  Vue.filter('capitalize', CapitalizeFilter)
  Vue.filter('time', time)
  Vue.filter('date', date)
  Vue.filter('datetime', datetime)
  Vue.filter('month', month)
}

/**
 * Vue.use 함수로 install 을 호출한 경우 이 함수가 호출됩니다.

 * @param Vue
 * @param options
 */
const installVue = (Vue, options) => {
  console.log('installVue@common/filter/index')
  installComponents(Vue, options)
  installFilters(Vue, options)
}

/**
 * nuxt.config.js 의 plugins 항목에 노출되어 Nuxt 플러그인으로 동작하는 경우 이 함수가 호출됩니다.
 *
 * @param context
 * @param inject
 */
const installNuxt = (context, inject) => {
  console.log('installNuxt@common/filter/index')
  const options = {}
  installComponents(Vue, options)
  installFilters(Vue, options)
}

/**
 * 플러그인 설치 함수입니다.
 *
 * - Vue.use 로 설치된 경우 첫번째 매개변수는 Vue 객체입니다.
 * - Nuxt plugin 으로 설치된 경우 첫 번째 매개변수의 'arguments[0].app.nuxt' 에 객체가 존재합니다.
 * - 두 경우에 해당하지 않는다면, 플러그인을 설치할 수 없습니다.
 */
export const install = function () {
  if (arguments[0] instanceof Vue) {
    installVue(...arguments)
  } else if (arguments[0].app.nuxt) {
    installNuxt(...arguments)
  }
}

export default install
