export default async ({ app, $axios, store }) => {
  await app.router.beforeEach(async (to, from, next) => {
    // 고객 Id가 있는 경우 GTM DataLayer Push
    window.dataLayer = window.dataLayer || []

    const userId = store.getters['session/getMb']?.mbEid ?? ''

    if (userId) {
      window.dataLayer.push({
        userId
      })
    }

    const doNext = true
    console.log('route.js')

    let isLogin = null
    await $axios.$post(process.env.API_BASE_URL + '/mb/mbb/token')
      .then((res) => {
        isLogin = res.success
      })
      .catch(() => { isLogin = false })

    if (!isLogin) {
      // 세션삭제
      store.commit('session/setMember', {})
    } else {
      await $axios.$post(process.env.API_BASE_URL + '/mb/mbb/getMbInfoByToken')
        .then((res) => {
          if (res.success) {
            store.commit('session/setMember', res.data)
          }
        })
    }

    if (doNext) {
      // 결제완료/결제실패 화면에서 뒤로가기 버튼 클릭시 장바구니 이동
      if (['/', '/or/ora/SCR_ORA_0006'].includes(from.path) && ['/or/ora/SCR_ORA_0001', '/or/ora/SCR_ORA_0007', '/fb/fba/SCR_FBA_0006', '/bp/bpa/SCR_BPA_0008'].includes(to.path)) {
        const orBackPath = localStorage.getItem('orBackPath')
        // console.log('◀■■ beforeEach::orBackPath', orBackPath)
        if (orBackPath) {
          await next({ path: orBackPath })
        } else {
          await next()
        }
      } else if (from.path === '/bp/bpa/SCR_BPA_0008' && to.path === '/bp/bpa/SCR_BPA_0001') { // 대량주문 주문서 뒤로가기 버튼 클릭시 장바구니 이동
        await next({ path: '/or/orc/SCR_ORC_0001?tab=04' })
      } else if (from.path === '/or/ORC/SCR_ORC_0001' && to.path === '/or/ora/SCR_ORA_0006') { // 장바구니에서 주문완료/실패 화면 이동
        await next(false)
      } else {
        await next()
      }
    } else {
      await next(false)
    }
  })

  // for groobee
  app.router.afterEach((to) => {
    const accountInfo = store.getters['session/getAccountInfo']
    const metaTags = [
      { hid: 'groobee:member_id', property: 'groobee:member_id', content: accountInfo?.mbMngNo ?? '' },
      { hid: 'groobee:member_grade', property: 'groobee:member_grade', content: '' },
      { hid: 'groobee:member_gender', property: 'groobee:member_gender', content: accountInfo?.sexdSe ?? '' },
      { hid: 'groobee:member_type', property: 'groobee:member_type', content: accountInfo?.userTy ?? '' },
      { hid: 'groobee:member_age', property: 'groobee:member_age', content: accountInfo?.ageGroup ?? '' }
    ]
    const currentMetaTags = app.head.meta
    metaTags.forEach((tag) => {
      const index = currentMetaTags.findIndex(existingTag => existingTag.hid === tag.hid)
      if (index !== -1) {
        currentMetaTags.splice(index, 1) // 기존 메타 태그 삭제
      }
    })
    app.head.meta = [...app.head.meta, ...metaTags]

    // const groobeeScript = document.getElementById('groobee-script')
    // if (groobeeScript) {
    //   groobeeScript.remove()
    // }
    switch (to.path) {
      case to.path.includes('/ds/exhCtgr/'):
      case '/bp/bpa/SCR_BPA_0008':
      case '/fb/fba/SCR_FBA_0006':
      case '/or/ora/SCR_ORA_0001':
      case '/or/ora/SCR_ORA_0006':
      case '/or/ora/SCR_ORA_0007':
      case '/or/orc/SCR_ORC_0001':
      case '/pd/pdr/SCR_PDR_0001':
        console.log('그루비 기타 페이지 수집 스크립트 예외 처리')
        break
      default: {
        // const script = document.createElement('script')
        // script.type = 'text/javascript'
        // script.id = 'groobee-script'
        // script.innerHTML = '(function(){groobee.start();})()'
        // document.head.appendChild(script)
        try {
          // eslint-disable-next-line no-undef
          groobee.start()
        } catch (e) {
          console.log(e)
        }
        break
      }
    }
  })
}
