import Vue from 'vue'
import 'tui-grid/dist/tui-grid.css'
import { Grid } from '@toast-ui/vue-grid'
import TuiGrid from 'tui-grid'

Vue.component('Grid', Grid)
Vue.component('TuiGrid', TuiGrid)
TuiGrid.applyTheme('clean')
TuiGrid.setLanguage('ko')

// TuiGrid.prototype.setBodyHeight(300)
// TuiGrid.applyTheme('striped')
TuiGrid.applyTheme('striped', {
  cell: {
    normal: {
      background: '#fff',
      border: '#e0e0e0',
      showVerticalBorder: true,
      showHorizontalBorder: true
    },
    invalid: {
      background: 'rgba(255, 0, 0, 0)'
    }
    // header: {
    //   background: '#fff',
    //   border: '#e0e0e0'
    // },
    // selectedHeader: {
    //   background: '#e0e0e0'
    // }
  }
})
export default ({ app }, inject) => {
  inject('grid', Grid)
  inject('TuiGrid', TuiGrid)
}
