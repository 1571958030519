import TimeFormatter from '@/plugins/common/time/time-formatter'

export const DEFAULT_LANGUAGE = 'ko-KR'

export const DEFAULT_FORMATS = {
  default: {
    DATE: 'yyyy-MM-dd',
    TIME: 'HH:mm:ss',
    DATETIME: 'yyyy-MM-dd HH:mm',
    MONTH: 'yyyy-MM'
  },
  'ko-KR': {
    DATE: 'yyyy-MM-dd',
    TIME: 'HH:mm:ss',
    DATETIME: 'yyyy-MM-dd HH:mm',
    MONTH: 'yyyy-MM'
  },
  'en-US': {
    DATE: 'dd-LLL-yyyy',
    TIME: 'HH:mm:ss',
    DATETIME: 'dd-LLL-yyyy HH:mm',
    MONTH: 'LLL-yyyy'
  }
}

export class InternationalTimeFormatter extends TimeFormatter {
  _language;

  constructor (language) {
    super()
    this.language = language
  }

  // eslint-disable-next-line accessor-pairs
  set language (language) {
    this._language = language || DEFAULT_LANGUAGE
  }

  formatTime (v, fmt) {
    return this.format(v, fmt || DEFAULT_FORMATS[this._language].TIME || DEFAULT_FORMATS.default.TIME)
  }

  formatDate (v, fmt) {
    return this.format(v, fmt || DEFAULT_FORMATS[this._language].DATE || DEFAULT_FORMATS.default.DATE)
  }

  formatDatetime (v, fmt) {
    return this.format(v, fmt || DEFAULT_FORMATS[this._language].DATETIME || DEFAULT_FORMATS.default.DATETIME)
  }

  formatMonth (v, fmt) {
    return this.format(v, fmt || DEFAULT_FORMATS[this._language].MONTH || DEFAULT_FORMATS.default.MONTH)
  }
}

export const internationalTimeFormatter = new InternationalTimeFormatter()
export default InternationalTimeFormatter
