import { createNamespacedStoreExposer } from '~/plugins/common/vuex/vuex-helper'

const init = () => ({
  member: {
    mbEid: undefined,
    mbEid2: undefined,
    mbEfnm: undefined,
    eeml: undefined,
    userTy: undefined,
    userSts: undefined,
    mbMngNo: undefined,
    lsCnctDtm: undefined,
    nomaskmbEid: undefined,
    sexdSe: undefined,
    ageGroup: undefined,
    crwYn: undefined,
    adltCtfcYn: undefined,
    adltCtfcCd: undefined,
    mbNknmNm: undefined,
    prfImg: undefined,
    noSessionYn: undefined,
    grpTyCd: undefined,
    crwGrpSeq: undefined,
    crwGrpNo: undefined,
    eci: undefined,
    // loginInfo: undefined,
    openAgrYn: undefined,
  },
  token: false,
  // ctfcData: undefined,
  bzmnNo: undefined,
  joinData: undefined,
  joinAction: undefined,
  replacePath: undefined,
})
const state = init

const getters = {
  getAccountInfo: state => state.member,
  // getMembEid: state => state.member.membEid,
  // getMembNm: state => state.member.membNm,
  // getMbpno: state => state.member.mbpno,
  // getEmail: state => state.member.email,
  // getRegDttm: state => state.member.mbpno,
  getToken: state => state.token,
  getMb: state => state.member,
  getMbEid: state => state.member.mbEid,
  getMbEid2: state => state.member.mbEid2,
  getMbEfnm: state => state.member.mbEfnm,
  getEeml: state => state.member.eeml,
  getUserTy: state => state.member.userTy,
  getUserSts: state => state.member.userSts,
  getMbMngNo: state => state.member.mbMngNo,
  getLsCnctDtm: state => state.member.lsCnctDtm,
  getNomaskmbEid: state => state.member.nomaskmbEid,
  // getCtfcData: state => state.ctfcData,
  getSexdSe: state => state.member.sexdSe,
  getCrwYn: state => state.member.crwYn,
  getAdltCtfcYn: state => state.member.adltCtfcYn,
  getAdltCtfcCd: state => state.member.adltCtfcCd,
  getMbNknmNm: state => state.member.mbNknmNm,
  getPrfImg: state => state.member.prfImg,
  getNoSessionYn: state => state.member.noSessionYn,
  getGrpTyCd: state => state.member.grpTyCd,
  getCrwGrpSeq: state => state.member.crwGrpSeq,
  getCrwGrpNo: state => state.member.crwGrpNo,
  getJoinData: state => state.joinData,
  getJoinAction: state => state.joinAction,
  getReplacePath: state => state.replacePath,
  getEci: state => state.eci,
  // getLoginInfo: state => state.loginInfo,
  getOpenAgrYn: state => state.member.openAgrYn,
}

const mutations = {
  init: (state) => {
    // state.member.membEid = undefined
    // state.member.membNm = undefined
    // state.member.mbpno = undefined
    // state.member.email = undefined
    // state.member.regDttm = undefined
    state.member.mbEid = undefined
    state.member.mbEid2 = undefined
    state.member.mbEfnm = undefined
    state.member.eeml = undefined
    state.member.userTy = undefined
    state.member.userSts = undefined
    state.member.mbMngNo = undefined
    state.member.lsCnctDtm = undefined
    state.member.nomaskmbEid = undefined
    state.member.sexdSe = undefined
    state.member.ageGroup = undefined
    state.member.crwYn = undefined
    state.member.adltCtfcYn = undefined
    state.member.adltCtfcCd = undefined
    state.member.mbNknmNm = undefined
    state.member.prfImg = undefined
    state.member.noSessionYn = undefined
    state.member.grpTyCd = undefined
    state.member.crwGrpSeq = undefined
    state.member.crwGrpNo = undefined
    state.member.eci = undefined
    // state.member.loginInfo = undefined
    state.member.openAgrYn = undefined
  },
  setMember: (state, member) => {
    // state.member.membEid = member.membEid
    // state.member.membNm = member.membNm
    // state.member.mbpno = member.mbpno
    // state.member.email = member.email
    // state.member.regDttm = member.regDttm
    state.member.mbEid = member.mbEid
    state.member.mbEid2 = member.mbEid2
    state.member.mbEfnm = member.mbEfnm
    state.member.eeml = member.eeml
    state.member.userTy = member.userTy
    state.member.userSts = member.userSts
    state.member.mbMngNo = member.mbMngNo
    state.member.lsCnctDtm = member.lsCnctDtm
    state.member.nomaskmbEid = member.nomaskmbEid
    state.member.sexdSe = member.sexdSe
    state.member.ageGroup = member.ageGroup
    state.member.crwYn = member.crwYn
    state.member.adltCtfcYn = member.adltCtfcYn
    state.member.adltCtfcCd = member.adltCtfcCd
    state.member.mbNknmNm = member.mbNknmNm
    state.member.prfImg = member.prfImg
    state.member.noSessionYn = member.noSessionYn
    state.member.grpTyCd = member.grpTyCd
    state.member.crwGrpSeq = member.crwGrpSeq
    state.member.crwGrpNo = member.crwGrpNo
    state.member.eci = member.eci
    // state.member.loginInfo = member.loginInfo
    state.member.openAgrYn = member.openAgrYn
  },
  pwdAuthenticationId: (state, payload) => (state.pwdAuthenticationId = payload),
  setToken: (state, payload) => (state.token = payload),
  // setCtfcData: (state, payload) => (state.ctfcData = payload),
  setMbMknmNm: (state, payload) => { state.member.mbNknmNm = payload },
  setPrfImg: (state, payload) => { state.member.prfImg = payload },
  setJoinData: (state, payload) => { state.joinData = payload },
  setJoinAction: (state, payload) => { state.joinAction = payload },
  setReplacePath: (state, payload) => { state.replacePath = payload },
  setNoSessionYn: (state, payload) => { state.noSessionYn = payload },
  setEci: (state, payload) => { state.eci = payload },
  // setLoginInfo: (state, payload) => { state.loginInfo = payload },
}

const actions = {
  init (commit) {
    commit('INIT')
  },
  async serverInit ({ commit }, { app }) {
    // CSRF 쿠키가 없으면 서버에 CSRF 토큰을 요청하고 쿠키를 생성
    if (!app.$cookies.get('CSRF-TOKEN')) {
      const csrfToken = await app.$axios.$get(process.env.apis.auth.csrf.uri)
      app.$cookies.set('CSRF-TOKEN', csrfToken['CSRF-TOKEN'], {
        path: '/',
        maxAge: 60 * 60 * 24
      })
    }
  }
  /*
  async setMember ({ commit }) {
    await this.$AxiosBuilder
      .params()
      .build()
      .get('/mb/login-member')
      .then((res) => {
        if (res.data.success) {
          commit('setMember', {
            membEid: res.data.data.membEid,
            membNm: res.data.data.membNm
            // mbpno: res.data.data.mbpno,
            // email: res.data.data.email,
            // regDttm: res.data.data.regDttm
          })
        }
      })
  }
  */
}

// 뷰 인스턴스에 노출하기 위한 노출 함수 제공.
export const { storeGetters, storeActions } = createNamespacedStoreExposer('session')(getters, actions)

// 스토어 내보내기.
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
