import { DateTime } from 'luxon'
import { isNOU } from '../expansions/condition'
// import invalid from 'luxon/src/impl/invalid';

const parsers = [
  DateTime.fromISO,
  DateTime.fromRFC2822,
  DateTime.fromSQL,
  DateTime.fromHTTP,
  DateTime.fromMillis,
  DateTime.fromSeconds,
  DateTime.fromJSDate,
  DateTime.fromObject
]

const parseStringDate = function (objectDate) {
  let error
  for (const parser of parsers) {
    try {
      const datetime = parser(objectDate)
      if (datetime.invalid) {
        error = new Error(datetime.invalid.explanation)
      } else {
        return datetime // 파싱에 성공하면 반환.
      }
    } catch (e) {
      error = e
    }
  }
  throw error // 파싱하지 못한 경우에만 발생.
}

const parseTime = (date) => {
  if (isNOU(date)) {
    return undefined
  }
  if (date instanceof DateTime) {
    return date
  }
  try {
    return parseStringDate(date)
  } catch (e) {
    console.error(e)
    return undefined
  }
}
export { parseTime }
export default parseTime
